import React, { useEffect, useState } from "react";
import {
  productWidget,
  productWidgetSearchIcon,
} from "./ProductWidget.module.scss";
import { hphContainer__productWidget } from "../../templates/HomePage/index.module.scss";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import DropdownTeamSize from "../DropdownTeamSize/DropdownTeamSize";
import SearchWhite from "../../images/design-components/search_white.svg";
import { navigate } from "gatsby";
import DropdownLocation from "../DropdownLocation/DropdownLocation";
import get from "lodash/get";
import i18n from "../../utils/i18n";
import { formatISO, isValid, parseISO } from "date-fns";
import cx from "classnames";
import classnames from "classnames";
import { locationOptions, types } from "./ProductWidgetData";
import { getQueryParams } from "../../utils";
import ModifiedDropdownDatePicker from "../DropdownDatePicker/Modified";

const ProductWidget = ({ defaultProduct, slice = {} }) => {
  const productType = get(slice, "primary.search_component", defaultProduct);

  const [showLocationDropdown, setShowLocationDropdown] = useState(false);

  const product = types[`${productType}`];

  const homePage = !(slice && slice.primary);

  const teamSizes = get(product, "teamSizes", []);

  const [location, setLocation] = useState(locationOptions[1].name);
  const [teamSize, setTeamSize] = useState("Team size");

  const [searchDisabled, setSearchDisabled] = useState(false);

  const params = getQueryParams();

  useEffect(() => {
    setSearchDisabled(isMeetingRooms && !params.get("type"));
  }, [params.get("type"), productType]);

  // HOURLY props
  const [date, setDate] = useState(new Date());

  const isPrivateOffices = productType === "Private Offices";
  const isDedicatedDesks = productType === "Dedicated Desks";
  const isHq = productType === "HQ by iQ";
  const isMeetingRooms = productType === "Meeting Rooms";

  const handleSubmit = async () => {
    if (searchDisabled) return;
    const params = getQueryParams();
    const newParams = new URLSearchParams();
    if (params.get("team_size")) {
      newParams.set("team_size", params.get("team_size"));
    }
    newParams.set("location", location);

    if (isPrivateOffices) {
      newParams.set("space_type", "Private office");
      newParams.set("location", location);

      await navigate(`${i18n.t("/lang/")}locations?${newParams.toString()}`);
    } else if (isHq) {
      newParams.set("space_type", "Private office");
      newParams.set("location", location);
      newParams.set("isHq", "true");

      if (!params.get("team_size")) {
        newParams.set("team_size", "41-50");
      }

      await navigate(`${i18n.t("/lang/")}locations?${newParams.toString()}`);
    } else if (isDedicatedDesks) {
      newParams.set("space_type", "Dedicated desks");

      newParams.set("team_size", params.get("team_size") || 1);
      await navigate(`${i18n.t("/lang/")}locations?${newParams.toString()}`);
    } else if (isMeetingRooms) {
      if (params.get("type") === "hourly") {
        const start = parseISO(params.get("start"));
        const end = parseISO(params.get("end"));
        if (isValid(start) && isValid(end)) {
          newParams.set("type", "hourly");
          newParams.set("start", formatISO(start));
          newParams.set("end", formatISO(end));
        }
      } else {
        const start = parseISO(params.get("start"));
        const end = parseISO(params.get("end"));

        if (isValid(start) && isValid(end)) {
          newParams.set("type", "daily");
          newParams.set("start", formatISO(start));
          newParams.set("end", formatISO(end));
        }
      }

      await navigate(
        `${i18n.t("/lang/")}search-meeting-rooms?${newParams.toString()}`,
      );
    }
  };

  return (
    <>
      <div
        className={cx(`container-xxl`, {
          [`mt-5`]: !homePage,
          [hphContainer__productWidget]: homePage,
          [`mt-4`]: homePage,
        })}
      >
        <div className={`row`}>
          <div className={cx({ [`offset-lg-1 col-lg-10`]: !homePage })}>
            {!homePage ? (
              <h2 className="text-center mb-3">
                {get(slice, "primary.title", "")}
              </h2>
            ) : (
              ""
            )}
            <div className="d-none d-md-block">
              <div
                className={`d-flex flex-row ${productWidget} ${
                  homePage && `border-0`
                }`}
              >
                <div
                  style={{
                    width:
                      isPrivateOffices || isHq || isDedicatedDesks
                        ? "45%"
                        : "20%",
                  }}
                >
                  <DropdownLocation
                    location={location}
                    setLocation={setLocation}
                    locationOptions={locationOptions}
                    productWidget={true}
                    show={showLocationDropdown}
                    setShow={setShowLocationDropdown}
                  />
                </div>
                <div
                  style={{
                    width:
                      isPrivateOffices || isHq || isDedicatedDesks
                        ? "45%"
                        : "33%",
                  }}
                >
                  <DropdownTeamSize
                    teamSize={teamSize}
                    setTeamSize={setTeamSize}
                    withSpaceTypePrompt={false}
                    productWidget={true}
                    teamSizes={teamSizes}
                    closeLocationDropDown={() => setShowLocationDropdown(false)}
                  />
                </div>
                {isMeetingRooms ? (
                  <div style={{ width: "50%" }}>
                    <div style={{ height: "100%" }}>
                      <ModifiedDropdownDatePicker
                        date={date}
                        hasHourly
                        productWidget
                        closeLocationDropDown={() =>
                          setShowLocationDropdown(false)
                        }
                        excludeDates={[new Date("2022-09-30")]}
                      />
                    </div>
                  </div>
                ) : null}

                <div
                  onClick={handleSubmit}
                  className={classnames({ ["homePageCta"]: true })}
                  style={{ opacity: searchDisabled ? 0.5 : 1 }}
                >
                  <SearchIcon searchDisabled={searchDisabled} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-md-none">
          <div className={`d-flex flex-column`}>
            <div className={`mb-1`}>
              <DropdownLocation
                location={location}
                setLocation={setLocation}
                locationOptions={locationOptions}
                productWidget={true}
                show={showLocationDropdown}
                setShow={setShowLocationDropdown}
              />
            </div>
            <div className={`mb-1`}>
              <DropdownTeamSize
                teamSize={teamSize}
                setTeamSize={setTeamSize}
                withSpaceTypePrompt={false}
                productWidget={productWidget}
                teamSizes={teamSizes}
              />
            </div>
            {isMeetingRooms ? (
              <div className={`mb-1`}>
                <ModifiedDropdownDatePicker
                  date={date}
                  hasHourly
                  productWidget
                />
              </div>
            ) : null}

            <div className={`d-flex justify-content-center mt-4`}>
              <Button
                variant={!homePage ? "primary" : "light homePageCta"}
                className="me-auto ms-auto"
                onClick={() => handleSubmit()}
              >
                <span>{i18n.t("homePage.Search")}</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isMeetingRooms && (
        <div
          className={cx({
            container: true,
            "mb-4 pb-2": homePage,
            "mt-3": !homePage,
          })}
        >
          <div className="row">
            <div className="col-12 text-center">
              <p className={cx({ "text-white": homePage })}>
                {i18n.t("mr.MultipleDaysText")}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductWidget;

const SearchIcon = ({ searchDisabled }) => {
  if (!searchDisabled) {
    return (
      <img
        alt="Search"
        src={SearchWhite}
        className={`${productWidgetSearchIcon} search-button`}
      />
    );
  }
  return (
    <OverlayTrigger
      placement={"top"}
      overlay={
        <Tooltip id={`meeting-rooms-search-tool-tip`}>
          {i18n.t("PickDateToGetStarted")}
        </Tooltip>
      }
    >
      <img
        alt="Search"
        src={SearchWhite}
        className={`${productWidgetSearchIcon} search-button`}
      />
    </OverlayTrigger>
  );
};
