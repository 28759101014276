import React, { useEffect, useState } from "react";
import get from "lodash/get";
import * as s from "./index.module.scss";
import {
  dropdownsMobile,
  dropdownsMobile__text,
  locationsEntry,
  locationsEntryImage,
  locationsListContainer,
  locationsMapContainer,
  locationsSelectorDesktop,
  locationsSelectorSection,
} from "./index.module.scss";
import {
  locationCenters,
  locationOptions,
  meetingRoomsTeamSizes as teamSizes,
} from "./_config";
import { Button } from "react-bootstrap";
import DropdownTeamSize from "../../components/DropdownTeamSize/DropdownTeamSize";
import { GoogleMap, Marker } from "../../components/GoogleMap";
import { LocationsContactFormNoResult } from "../../components/LocationsContactForm/LocationsContactForm";
import getLocationsListMeetingRooms from "../../utils/locationsRequests/getLocationsListMeetingRooms";
import DropdownLocation from "../../components/DropdownLocation/DropdownLocation";
import ModifiedDropdownDatePicker from "../../components/DropdownDatePicker/Modified";
import { StringParam, useQueryParam } from "use-query-params";
import { Link } from "gatsby";
import ProgressDots from "../../components/ProgressDots";
import { memberPortalUrl } from "../../../config";
import { getQueryParams } from "../../utils";
import CityList from "../../components/LocationsPage/CityList";
import i18n from "../../utils/i18n";
import cx from "classnames";
import RightArrow from "../../images/design-components/Right.svg";
import { isValid, parseISO } from "date-fns";
import calculateGapInDaysOrHours from "../../utils/calculateGapInDaysOrHours";

const SearchMeetingRooms = ({ cmsLocations }) => {
  const [location = "Toronto", setLocation] = useQueryParam(
    "location",
    StringParam,
  );
  const [teamSize, setTeamSize] = useQueryParam("team_size", StringParam);

  const [mapCenter, setCenter] = useState({
    lat: 43.6508797,
    lng: -79.3834781,
  });

  useEffect(() => {
    const match = locationCenters.find(
      (center) => center.location === location,
    );
    if (match) {
      setCenter(match.center);
    }
  }, [location]);

  const [start] = useQueryParam("start", StringParam);
  const [end] = useQueryParam("end", StringParam);

  // HOURLY props
  const [date, setDate] = useState(new Date());

  // Results
  const [meetingRoomsList, setMeetingRoomsList] = useState([]);
  const [meetingRoomsListByLocation, setMeetingRoomsListByLocation] = useState(
    [],
  );
  const [locationHovered, setLocationHovered] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getLocationsListMeetingRooms(cmsLocations).then((res) => {
      setMeetingRoomsList(res);
      setLoading(false);
    });
  }, [teamSize, end, location]);

  useEffect(() => {
    setMeetingRoomsListByLocation(
      meetingRoomsList.filter((loc) => {
        const userCity = location || "Toronto";
        return get(loc, "location.city", "") === userCity;
      }),
    );
  }, [meetingRoomsList, location]);

  return (
    <>
      <div className={`container-xxl ${locationsSelectorSection}`}>
        <h5>
          <span>{i18n.t(`locationPage.OnDemandWorkspaces`)}:</span>
          <span> {i18n.t(`homePage.MeetingRooms`)}</span>
        </h5>

        <div className={"d-none d-md-block"}>
          {/*// DESKTOP - 4 locations, team size dropdown + start & end date**/}
          <div className={`${locationsSelectorDesktop}`}>
            {/*& > div:nth-child(1)*/}
            <div>
              <CityList />
            </div>
            <div className={`d-flex justify-content-center`}>
              <div className={`mx-3 team-size-remove-gap`}>
                <DropdownTeamSize
                  teamSize={teamSize}
                  setTeamSize={setTeamSize}
                  withSpaceTypePrompt={false}
                  teamSizes={teamSizes}
                />
              </div>
              <div className={`mx-3`}>
                <ModifiedDropdownDatePicker
                  date={date}
                  hasHourly
                  excludeDates={[new Date("2022-09-30")]}
                />
              </div>
            </div>
            <div className={`container-xxl mt-xl-5`}>
              <div className={`d-flex justify-content-between`}>
                <div className={cx([`d-xl-inline-block d-none`])}>
                  <p className={`mb-0 w-75`}>
                    {i18n.t("SelectTeamSizeToViewPrices")}
                  </p>
                </div>
                <div>
                  <Button
                    onClick={() =>
                      window.open(memberPortalUrl, "_blank").focus()
                    }
                    className={`d-xl-inline-block d-none btn-flex`}
                    variant="info"
                  >
                    <span>{i18n.t("AlreadyMember")}</span>
                  </Button>
                </div>
              </div>
            </div>
            <p className="d-xl-none d-sm-inline-block d-none text-center w-100 mt-3 mb-0 fst-normal text-reset">
              {i18n.t("SelectTeamSizeToViewPrices")}
            </p>
          </div>
        </div>
        <div className={"d-block d-md-none"}>
          <div className={dropdownsMobile}>
            <div className="d-flex flex-column">
              <div className="w-100 mb-2">
                <DropdownLocation
                  location={location}
                  setLocation={setLocation}
                  locationOptions={locationOptions}
                />
              </div>
              <div className="w-100 mb-2">
                <DropdownTeamSize
                  teamSize={teamSize}
                  setTeamSize={setTeamSize}
                  withSpaceTypePrompt={false}
                  teamSizes={teamSizes}
                />
              </div>
              <div className="w-100">
                <ModifiedDropdownDatePicker date={date} hasHourly />
              </div>
              <div>
                <p className={dropdownsMobile__text}>
                  {i18n.t("SelectTeamSizeToViewPrices")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Map + LocationsList START*/}
      <div className={`container-xxl`}>
        <div className={`row`}>
          <div
            className={cx(
              locationsMapContainer,
              `col-lg-6 offset-lg-0 order-lg-1`,
              `col-md-8 offset-md-2`,
            )}
          >
            <GoogleMap center={mapCenter}>
              {meetingRoomsListByLocation.map((loc) => {
                return loc.meeting_rooms.map((room, index) => {
                  const lat = get(loc, "location.lat", 0);
                  const lng = get(loc, "location.lng", 0);
                  if (!lat || !lng || !loc.hasMeetingRooms) return null;
                  return (
                    <Marker
                      key={room.meetingRoomId}
                      lat={lat}
                      lng={lng}
                      name={get(loc, "location.name", "")}
                      show={room.meetingRoomId === locationHovered ? true : ""}
                    />
                  );
                });
              })}
            </GoogleMap>
          </div>
          <div
            className={`col-lg-6 order-lg-0 d-flex flex-column ${locationsListContainer} ${s.locationsListContainerMeetingRooms}`}
          >
            <>
              {loading ? (
                <>
                  <ProgressDots />
                </>
              ) : meetingRoomsListByLocation.length > 0 ? (
                meetingRoomsListByLocation.map((loc, index) => {
                  if (!loc.hasMeetingRooms) return null;
                  return (
                    <div key={index}>
                      <h4 className={`mb-2`}>
                        {formatMeetingRoomName(loc.location.name)}
                      </h4>
                      {loc.meeting_rooms.map((room, index) => {
                        const params = new URLSearchParams();
                        const existingParams = getQueryParams();
                        params.set("profile_type", "meeting-rooms");
                        params.set("meeting_room_id", room.meetingRoomId);
                        params.set("location", existingParams.get("location"));
                        params.set("meeting_room_name", room.name);
                        params.set("type", existingParams.get("type"));
                        params.set("rnd_location_id", loc.location.locationId);
                        if (existingParams.get("team_size")) {
                          params.set(
                            "team_size",
                            existingParams.get("team_size"),
                          );
                        }
                        if (
                          existingParams.get("start") &&
                          existingParams.get("end")
                        ) {
                          params.set("start", existingParams.get("start"));
                          params.set("end", existingParams.get("end"));
                        }

                        return (
                          <Link
                            to={`${get(
                              loc,
                              "cmsLocation.url",
                              "/search-meeting-rooms",
                            )}?${params.toString()}`}
                            key={index}
                            role={"presentation"}
                            className={`${locationsEntry} ${s.locationsEntryMeetingRooms} cursor-pointer`}
                            onMouseEnter={() =>
                              setLocationHovered(room.meetingRoomId)
                            }
                            onMouseLeave={() => {
                              setLocationHovered("");
                            }}
                          >
                            <div className="row">
                              <div className="col-sm-6">
                                <div className={locationsEntryImage}>
                                  <img
                                    alt={room.name}
                                    src={`https:${room.image}`}
                                  />
                                </div>
                              </div>
                              <div className="col-sm-6 d-flex flex-column">
                                <h5>{formatMeetingRoomName(room.name)}</h5>
                                <p
                                  className={
                                    teamSize === "Team size" ? "d-none" : ""
                                  }
                                >
                                  {i18n.t("Upto")} {room.capacity}{" "}
                                  {i18n.t("locationPage.People")}
                                </p>
                                <Pricing data={room} />

                                <div className={`d-flex mt-auto`}>
                                  <div className={`d-flex ms-auto`}>
                                    <p className={`mb-0 text-nowrap`}>
                                      {i18n.t("ExpressCheckout")}
                                    </p>
                                    <img
                                      alt=""
                                      src={RightArrow}
                                      className={`ms-2`}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </div>
                  );
                })
              ) : (
                <LocationsContactFormNoResult show={true} />
              )}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchMeetingRooms;

const Pricing = ({ data = {} }) => {
  const dailyRate = get(data, "rates.dailyRate.price", false);
  const hourlyRate = get(data, "rates.hourlyRate.price", false);
  const params = new URLSearchParams(window.location.search);
  const from = parseISO(params.get("start"));
  const to = parseISO(params.get("end"));
  const type = params.get("type");
  const isHourly = type === "hourly";
  let customPrice = false;
  if (type && isValid(from) && isValid(to)) {
    const gap = calculateGapInDaysOrHours(from, to, type === "hourly");
    const selectedPrice = isHourly ? hourlyRate : dailyRate;
    customPrice = selectedPrice * gap;
    if (hourlyRate && customPrice >= dailyRate) {
      customPrice = dailyRate;
    }
  }
  if (customPrice) {
    return <p className={s.meeting_rooms_pricing}>${customPrice} Total</p>;
  }
  return (
    <p>
      <strong>
        {dailyRate && (
          <>
            ${dailyRate}/{i18n.t("day")}
          </>
        )}
        {dailyRate && hourlyRate && <br />}
        {hourlyRate && (
          <>
            ${hourlyRate}/{i18n.t("hour")}
          </>
        )}
      </strong>
    </p>
  );
};

export const formatMeetingRoomName = (name) => {
  const lastIndexOfSpace = name.lastIndexOf(" ");

  if (lastIndexOfSpace === -1) {
    return name;
  }

  return name.substring(0, lastIndexOfSpace);
};
