import React from "react";
import { Button } from "react-bootstrap";
import {
  contactButton,
  contactContainer,
  contactImageContainer,
  contactPerson,
  contactSection,
  floatingContactIcon,
} from "./StickyContact.module.scss";
import get from "lodash/get";
import resolvePrismicLink from "../../utils/resolvePrismicLink";
import { navigate } from "gatsby";
import ModalForm from "./ModalForm";

const StickyContact = ({
  price,
  profile = {},
  cmsProfile,
  showModal = null,
  showContact = null,
  slice = null,
}) => {
  if (!slice?.id && !profile?.id) return null;

  const modalVisible = slice?.primary?.show_in_a_modal;

  const { link, target } = resolvePrismicLink(slice?.primary?.button_link);
  return (
    <>
      <div className={`d-none d-md-block ${contactSection}`}>
        <div className={`${contactContainer}`}>
          <div className={`container-xxl d-flex`}>
            <img
              alt={profile?.contact_name || slice?.primary?.name || ""}
              className={contactImageContainer}
              src={
                profile?.user_image_url || slice?.primary?.user_image?.url || ""
              }
            />
            <div className={contactPerson}>
              <h5>{profile?.contact_name || slice?.primary?.name || ""}</h5>
              <h5>
                {profile?.contact_position || slice?.primary?.title || ""}
              </h5>
            </div>
            <div className={contactButton}>
              {modalVisible && <ModalForm slice={slice} />}
              {!modalVisible && !!link ? (
                <Button
                  onClick={async () => {
                    if (target === "_blank") {
                      window.open(link);
                    } else {
                      await navigate(link);
                    }
                  }}
                  className={"px-3 px-lg-4"}
                >
                  <span>
                    {cmsProfile?.get_in_touch_button_label ||
                      slice?.primary?.button_label ||
                      "Get in Touch"}
                  </span>
                </Button>
              ) : null}
              {typeof showModal === "function" && (
                <Button
                  onClick={showModal}
                  className={"ms-2 px-3 px-lg-4"}
                  variant={"primary"}
                >
                  <span>
                    {get(cmsProfile, "book_tour_button_label", "Book a Tour") ||
                      "Book a Tour"}
                  </span>
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={floatingContactIcon}>
          <img
            alt={get(profile, "contact_name", "")}
            src={get(profile, "user_image_url", "")}
          />
        </div>
      </div>
    </>
  );
};

export default StickyContact;
